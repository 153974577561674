import { useEffect, useState, VFC } from 'react';
import { Button, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import VideoCard from '../Room/partials/VideoCard';
import { Root, SettingsWrap, TextField } from './styled';
import { RootState } from '../../store';
import { setRoomId, setUserMeetName } from '../../store/room';
import Room from '../Room';
import SettingsModal from '../SettingsModal';
import ControlPanel from '../ControlPanel';

import useRoom from '../../hooks/useRoom';
import { useParams } from 'react-router';
import { logEvent, setRoomId as setLoggerRoomId } from '../../utils/debug';
import RateLesson from './RateLesson';
import { useTranslation } from 'react-i18next';

const SettingsBeforeConnection: VFC = () => {
  const { t } = useTranslation();
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  let { roomId } = useParams<{ roomId: string }>();
  roomId = String(roomId).toLowerCase();
  setLoggerRoomId(roomId);

  const {
    handleConnectRoom,
    toggleScreenSharing,
    toggleMicro,
    toggleCamera,
    leaveRoom,
    isInitialization,
    isJoined,
  } = useRoom({ roomId });

  const {
    room: {
      stats,
      clients: { localUser },
    },
  } = useSelector((state: RootState) => state);

  const { userMeetName, userId, tracks } = localUser;
  const { isVideoEnabled, isMicroEnabled } = tracks;
  const dispatch = useDispatch();

  const handleOpenSettings = () => setShowSettingsModal(true);

  useEffect(() => {
    dispatch(setRoomId(roomId));
  }, [dispatch, roomId]);

  useEffect(() => {
    logEvent({
      event: 'open_room_page',
      userId,
      roomId,
      data: {
        userAgent: window.navigator.userAgent,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
  }, [roomId, userId]);

  return isJoined ? (
    <Room
      toggleCamera={toggleCamera}
      toggleMicro={toggleMicro}
      toggleScreenSharing={toggleScreenSharing}
      leaveRoom={leaveRoom}
    />
  ) : (
    <Container style={{ height: '100%' }}>
      <Root>
        <div style={{ padding: '32px 0' }}>
          <SettingsModal
            open={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
          />
          <div style={{ height: 250 }}>
            <VideoCard
              isSelf
              client={localUser}
              stats={stats['localUser']}
              localStats={stats['localUser']}
            />
          </div>

          <ControlPanel
            cameraEnabled={isVideoEnabled}
            microEnabled={isMicroEnabled}
            onToggleCamera={toggleCamera}
            onToggleMicro={toggleMicro}
            onToggleSettings={handleOpenSettings}
          />
          <SettingsWrap style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={userMeetName}
              label={t('name')}
              onChange={(event: any) =>
                dispatch(setUserMeetName(event.target.value))
              }
              style={{ marginBottom: 10 }}
            ></TextField>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleConnectRoom}
              disabled={isInitialization}
              id="btn-connect"
            >
              {t('connect')}
            </Button>
            <RateLesson />
          </SettingsWrap>
        </div>
      </Root>
    </Container>
  );
};

export default SettingsBeforeConnection;
