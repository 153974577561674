import { Button } from '@mui/material';
import Jitsi from 'lib-jitsi-meet';
import { useParams } from 'react-router-dom';
import { getConnectionToJitsiServer } from '../hooks/useRoom/helpers';
import { useEffect, useState } from 'react';
import Audio from './Room/partials/Audio';
import Video from './Room/partials/Video';
import { JitsiLocalTrack } from '../types/Jitsi';

const { CONNECTION_ESTABLISHED } = Jitsi.events.connection;
const { TRACK_ADDED } = Jitsi.events.conference;

// let chunks: any[] = [];
// let recorder: any;

// function download() {
//   let blob = new Blob(chunks, { type: 'video/webm' });
//   let url = window.URL.createObjectURL(blob);
//   let a = document.createElement('a');
//   a.style.display = 'none';
//   a.href = url;
//   a.download = 'test.webm';
//   document.body.appendChild(a);
//   a.click();
//   setTimeout(function () {
//     document.body.removeChild(a);
//     window.URL.revokeObjectURL(url);
//   }, 100);

//   recorder.stop();
// }

const Viewer = () => {
  const [connected, setConnected] = useState(false);
  const [dimensions, setDimensions] = useState<any>({});
  const [clients, setClients] = useState({});
  let { roomId } = useParams<{ roomId: string }>();
  roomId = String(roomId).toLowerCase();

  const onConnect = () => {
    setConnected(true);
    const conn = getConnectionToJitsiServer();
    // @ts-ignore
    window.progkids.connection = conn;

    conn.addEventListener(CONNECTION_ESTABLISHED, () => {
      const room = conn.initJitsiConference(roomId, {
        p2p: { enabled: false },
      });

      // @ts-ignore
      window.progkids.room = room;

      room.setLocalParticipantProperty(
        'isRecorder',
        JSON.stringify({ isRecorder: true })
      );
      room.setReceiverConstraints({
        lastN: 5,
        constraints: {},
        defaultConstraints: { idealHeight: 720, maxHeight: 720 },
      });
      // room.setAssumedBandwidthBps(100);
      room.setDisplayName('VIEWER');

      const update = () => {
        const participants = room.getParticipants();
        console.log('participants count', participants.length);
        const clients = participants.reduce((acc: any, participant: any) => {
          const jitsiUserId = participant.getId();

          acc[jitsiUserId] = {
            name: participant.getDisplayName(),
            tracks: participant.getTracks(),
          };

          return acc;
        }, {});

        setClients(clients);
      };

      room.on(TRACK_ADDED, (track: JitsiLocalTrack) => {
        console.log('track added', track.getType(), track);
        update();

        // track.on(TRACK_VIDEOTYPE_CHANGED, (track: JitsiLocalTrack) => {
        //   console.log(track);

        //   if (track && track.videoType === 'descktop') {
        //     console.log('!!!!!!!');
        //   }
        // });

        // if (track && track.videoType === 'desktop') {
        //   console.log('!!!!!!!');

        // track.track.applyConstraints({
        //   assumedBandwidthBps: 1,
        //   constraints: {},
        //   defaultConstraints: { idealHeight: 320, maxHeight: 320 },
        // });
        // recorder = new MediaRecorder(track.stream, {
        //   videoBitsPerSecond: 200,
        // });

        // chunks = [];
        // recorder.ondataavailable = (e: any) => {
        //   console.log(e.data.size);
        //   chunks.push(e.data);
        // };
        // recorder.start(4000);
        // }
      });
      room.join('', true);
      update();
    });

    conn.connect({} as any);
  };

  useEffect(() => {
    Jitsi.setLogLevel(Jitsi.logLevels.DEBUG);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setDimensions(
        Object.keys(clients).reduce((acc, clientId) => {
          const client = (clients as any)[clientId];
          client.tracks.forEach((track: JitsiLocalTrack) => {
            const id = track.getId();
            if (!id) return;

            const quality = track.containers[0].getVideoPlaybackQuality
              ? track.containers[0].getVideoPlaybackQuality()
              : {};
            acc[id] = {
              width: track.getWidth(),
              height: track.getHeight(),
              quality: {
                total: quality.totalVideoFrames,
                dropped: quality.droppedVideoFrames,
              },
            };
          });

          return acc;
        }, {} as any)
      );
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, [clients]);

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {!connected && (
        <Button variant="contained" onClick={onConnect}>
          Connect
        </Button>
      )}
      <span>
        participants: <b>{Object.keys(clients).length} </b>
        <a href={`https://app.progkids.com/i/gui/new-lessons/${roomId}`}>
          Lesson link
        </a>
      </span>
      {Object.keys(clients).map((id) => {
        const client = (clients as any)[id];
        return (
          <div key={id}>
            {id} {client.name}
            {client.tracks.map((track: any) => {
              const id = track.getId();
              const type = track.getType();

              const isCamera = track.getVideoType() === 'camera';
              const videoStyles = { width: isCamera ? '200px' : '100%' };
              const dim = dimensions[id] || {};

              return type === 'audio' ? (
                <Audio key={id} track={track} />
              ) : (
                <div
                  key={id}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Video track={track} style={videoStyles} />
                  {dim.width} x {dim.height} x{' '}
                  {JSON.stringify(dim.quality, null)}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Viewer;
